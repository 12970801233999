//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-180:_748,_820,_448,_492,_228,_500,_272,_0;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-180')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-180', "_748,_820,_448,_492,_228,_500,_272,_0");
        }
      }catch (ex) {
        console.error(ex);
      }